import { default as indexB4Zy8IJX5CMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/auth/password/reset/index.vue?macro=true";
import { default as _91uuid_93cyEK7NzQ8wMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/auth/password/reset/submit/[uuid].vue?macro=true";
import { default as verify_45_91otpId_93ravvHqGPytMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/auth/verify-[otpId].vue?macro=true";
import { default as index77sN6gby0rMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/admins/index.vue?macro=true";
import { default as indexlnOJNfc8raMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/affiliates/index.vue?macro=true";
import { default as assignedcountSV2DDX4VnJMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/assignedcount.vue?macro=true";
import { default as assignedtimesMkJ9yPcmFoMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/assignedtimes.vue?macro=true";
import { default as closingtimesdJVJr3duPgMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/closingtimes.vue?macro=true";
import { default as index5njGD4ItrvMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/index.vue?macro=true";
import { default as lostcount0LehMr17vrMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/lostcount.vue?macro=true";
import { default as woncountJAV8Vk6trxMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/woncount.vue?macro=true";
import { default as analyticsCCjxZ4UCiFMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics.vue?macro=true";
import { default as indexNjMJcOFtv2Meta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/index.vue?macro=true";
import { default as importc0dM9tKlMBMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/import.vue?macro=true";
import { default as industrieshM1LsbhHGGMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/industries.vue?macro=true";
import { default as locationsLWiNHJwKThMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/locations.vue?macro=true";
import { default as sourcesyuJOEKng0vMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/sources.vue?macro=true";
import { default as tickets7QvkcGnsZyMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/tickets.vue?macro=true";
import { default as manageIfZo3KFbZZMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage.vue?macro=true";
import { default as claimpDah6FrACDMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads/claim.vue?macro=true";
import { default as indexSc2g2PKzxRMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads/index.vue?macro=true";
import { default as salesflowO9Mv6zvo6JMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads/salesflow.vue?macro=true";
import { default as myleadsdx9pxbk7hTMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads.vue?macro=true";
import { default as crmmuC2dsZdVEMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm.vue?macro=true";
import { default as indexua3eYBxRmDMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/financial/index.vue?macro=true";
import { default as index85AEEwTZovMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/index.vue?macro=true";
import { default as _91orderId_93eFDTBJmC1QMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/orders/[orderId].vue?macro=true";
import { default as indexMYLHsKLoXtMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/orders/index.vue?macro=true";
import { default as addfSIWkPgRGqMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/addons/add.vue?macro=true";
import { default as removeQI2HzVtm4JMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/addons/remove.vue?macro=true";
import { default as index4lsyNy2Vc2Meta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/index.vue?macro=true";
import { default as add1EfqsPAnmWMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/subscription/add.vue?macro=true";
import { default as indexsnvxLxb7rUMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/index.vue?macro=true";
import { default as syncaccesseY7zCH3t3NMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/syncaccess.vue?macro=true";
import { default as indexRaFMruou3tMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/plans/index.vue?macro=true";
import { default as _91id_93AFUqMIPomfMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/print/order/[id].vue?macro=true";
import { default as index7L0TW9977HMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/promotions/index.vue?macro=true";
import { default as indexjxi1WIoUkGMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/subscription-renewals/index.vue?macro=true";
import { default as _91userId_931WaXxBYR83Meta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/users/[userId].vue?macro=true";
import { default as index5VbwlbOLjGMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/users/index.vue?macro=true";
import { default as indexAY0RZ8Xoj6Meta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/zatca/index.vue?macro=true";
import { default as dashboardQotj6kbV5QMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/dashboard.vue?macro=true";
import { default as indexaS4S7fUtOeMeta } from "/home/runner/work/mezan/mezan/apps/admin/pages/index.vue?macro=true";
export default [
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/auth/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-submit-uuid",
    path: "/auth/password/reset/submit/:uuid()",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/auth/password/reset/submit/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-otpId",
    path: "/auth/verify-:otpId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/auth/verify-[otpId].vue").then(m => m.default || m)
  },
  {
    name: dashboardQotj6kbV5QMeta?.name,
    path: "/dashboard",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-admins",
    path: "admins",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/admins/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-affiliates",
    path: "affiliates",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: crmmuC2dsZdVEMeta?.name,
    path: "crm",
    meta: crmmuC2dsZdVEMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsCCjxZ4UCiFMeta?.name,
    path: "analytics",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-crm-analytics-assignedcount",
    path: "assignedcount",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/assignedcount.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-assignedtimes",
    path: "assignedtimes",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/assignedtimes.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-closingtimes",
    path: "closingtimes",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/closingtimes.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-lostcount",
    path: "lostcount",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/lostcount.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-woncount",
    path: "woncount",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/analytics/woncount.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-crm",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage",
    path: "manage",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-crm-manage-import",
    path: "import",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-industries",
    path: "industries",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/industries.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-locations",
    path: "locations",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/locations.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-sources",
    path: "sources",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/sources.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-tickets",
    path: "tickets",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/manage/tickets.vue").then(m => m.default || m)
  }
]
  },
  {
    name: myleadsdx9pxbk7hTMeta?.name,
    path: "myleads",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-crm-myleads-claim",
    path: "claim",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads/claim.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-myleads",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-myleads-salesflow",
    path: "salesflow",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/crm/myleads/salesflow.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "dashboard-financial",
    path: "financial",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/financial/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    meta: index85AEEwTZovMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-orders-orderId",
    path: "orders/:orderId()",
    meta: _91orderId_93eFDTBJmC1QMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-orders",
    path: "orders",
    meta: indexMYLHsKLoXtMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-addons-add",
    path: "organizations/:orgId()/addons/add",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/addons/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-addons-remove",
    path: "organizations/:orgId()/addons/remove",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/addons/remove.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId",
    path: "organizations/:orgId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-subscription-add",
    path: "organizations/:orgId()/subscription/add",
    meta: add1EfqsPAnmWMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/[orgId]/subscription/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations",
    path: "organizations",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-syncaccess",
    path: "organizations/syncaccess",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/organizations/syncaccess.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-plans",
    path: "plans",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-print-order-id",
    path: "print/order/:id()",
    meta: _91id_93AFUqMIPomfMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/print/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-promotions",
    path: "promotions",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subscription-renewals",
    path: "subscription-renewals",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/subscription-renewals/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users-userId",
    path: "users/:userId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "users",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-zatca",
    path: "zatca",
    meta: indexAY0RZ8Xoj6Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/dashboard/zatca/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/mezan/mezan/apps/admin/pages/index.vue").then(m => m.default || m)
  }
]